import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'


import pic_theme_ecommerce from '../assets/images/react-themes-ecommerce.jpg'
import pic_theme_spectral from '../assets/images/react-themes-spectral.jpg'
import pic_theme_stellar from '../assets/images/react-themes-stellar.jpg'
import pic_theme_photon from '../assets/images/react-themes-photon.jpg'
import pic_theme_dimension from '../assets/images/react-themes-dimension.jpg'
import pic_theme_emma from '../assets/images/react-themes-emma.jpg'
import pic_theme_materialkit from '../assets/images/react-themes-material-kit.jpg'
import pic_theme_jodie from '../assets/images/react-themes-jodie.jpg'
import pic_theme_home from '../assets/images/react-themes-home.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="React Themes - Index provided by @AppSeed.us"
                    meta={[
                        { name: 'description', content: 'React Themes. Crafted by HTML5 Up, LekoArts, Creative-Tim. Enhanced by AppSeed' },
                        { name: 'keywords', content: 'react-themes, react, themes, html5up, lekoarts, creative-tim, stellar, photon, dimension, material kit, appseed' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">

                        <article style={{backgroundImage: `url(${pic_theme_ecommerce})`}}>
                            <header className="major">
                                <h3>eCommerce</h3>
                                <p> GatsbyJS / React theme for eCommerce Apps</p>
                            </header>
                            <a target="_blank" rel="noopener noreferrer" href="https://react-themes-ecommerce.appseed.us/" className="link primary">&nbsp;</a>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_stellar})`}}>
                            <header className="major">
                                <h3>Stellar</h3>
                                <p> React theme crafted by HTML5 Up</p>
                            </header>
                            <Link className="link primary" to="/react-themes-stellar"></Link>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_photon})`}}>
                            <header className="major">
                                <h3>Photon</h3>
                                <p> React theme crafted by HTML5 Up</p>
                            </header>
                            <Link className="link primary" to="/react-themes-photon"></Link>
                        </article>                        


                        <article style={{backgroundImage: `url(${pic_theme_dimension})`}}>
                            <header className="major">
                                <h3>Dimension</h3>
                                <p> Free React Theme crafted by Html5 Up</p>
                            </header>
                            <Link className="link primary" to="/react-themes-dimension"></Link>
                        </article>

                        <article style={{backgroundImage: `url(${pic_theme_emma})`}}>
                            <header className="major">
                                <h3>Emma</h3>
                                <p> GatsbyJS / React theme designed by LekoArts</p>
                            </header>
                            <a target="_blank" rel="noopener noreferrer" href="https://starter-gatsbyjs-emma.appseed.us/" className="link primary">&nbsp;</a>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_materialkit})`}}>
                            <header className="major">
                                <h3>Material Kit</h3>
                                <p> GatsbyJS / React theme designed by Creative-Tim</p>
                            </header>
                            <a target="_blank" rel="noopener noreferrer" href="https://starter-gatsbyjs-material-kit.appseed.us/" className="link primary">&nbsp;</a>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_jodie})`}}>
                            <header className="major">
                                <h3>Jodie</h3>
                                <p> GatsbyJS / React theme designed by LekoArts</p>
                            </header>
                            <a target="_blank" rel="noopener noreferrer" href="https://starter-gatsbyjs-jodie.appseed.us/" className="link primary">&nbsp;</a>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_spectral})`}}>
                            <header className="major">
                                <h3>(WIP) Spectral</h3>
                                <p>React theme crafted by HTML5 Up </p>
                            </header>
                            <Link className="link primary" to="/react-themes-spectral"></Link>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_home})`}}>
                            <header className="major">
                                <h3>This App</h3>
                                <p>Open-Source is the new sexy. @Click and get sources</p>
                            </header>
                            <a target="_blank" rel="noopener noreferrer" className="link primary" href="https://github.com/react-theme/index"></a>
                        </article>                        
                        
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>About</h2>
                            </header>
                            <p>This index will be constanly updated. Stay tuned!
                            <br />Where to go from here? 
                            Read more about <a target="_blank" rel="noopener noreferrer" href="https://blog.appseed.us/react-themes-dashboards-landing-pages/">React Rhemes</a> on our blog or 
                            {' '}<a target="_blank" rel="noopener noreferrer" href="https://appseed.us?ref=react-themes">Generate a React App</a> in a few minutes (no account needed).
                            </p>
                         </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex