import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>React Themes</h1>
            </header>
            <div className="content">
                <p>Beautiful Themes, Designed by HTML5 Up, LekoArts, Creative-Tim.{' '}
                <br />Coded in React by @AppSeed.us</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
